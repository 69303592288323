<template>
  <main class="view view--day">
    <section class="header">
      <div class="header__center">
        <p class="title" v-html="$t('Choose an account')"></p>
      </div>
    </section>

    <section class="tasks">
      <div
        v-on:click="accountSelected(account)"
        class="task"
        v-for="account in accounts"
        :key="account.id"
      >
        {{ account.name }}
      </div>
    </section>
  </main>
</template>

<script>
export default {
  methods: {
    accountSelected: function (account) {
      this.$api.getAccountDetails(account.id).then((data) => {
        var self = this;

        if (typeof data.error != "undefined") {
          this.$config.clear("jwt");
          this.$config.clear("account");
          this.$router.replace({ name: "login", query: { error: data.error } });
        } else {
          this.$database.clearAllData("equipment").then(() => {
            self.$database.clearAllData("articles").then(() => {
              self.$database.clearAllData("settings").then(() => {
                self.$database.clearAllData("slots").then(() => {
                  self.$config.set("account", data);
                  self.$router.push({ name: "home" });
                });
              });
            });
          });
        }
      });
    },
  },
  mounted: function () {
    if (this.$route.params.accounts) {
      this.$config.set("accounts", this.$route.params.accounts);
    }

    this.accounts = this.$config.get("accounts");
  },
  data: function () {
    return {
      accounts: [],
    };
  },
};
</script>
